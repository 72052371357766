import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import "./index.css";
//import "./custom.css";
//import "./custom2.css";
import "./assets/css/nouislider.css";
import "./assets/css/style.css";

import { Provider } from "react-redux";
import store from "./store";
import { registerObserver } from 'react-perf-devtool';

const Home = React.lazy(() => import('views/home'))
const Exchange = React.lazy(() => import('views/Exchange/exchange'))
const Liquidity = React.lazy(() => import('views/liquidity'))
const Referral = React.lazy(() => import('views/referral'))
const Games		= React.lazy(()=> import('views/Games'))
const Lottery	= React.lazy(()=> import('views/Lottery'))
const Diamond	= React.lazy(()=> import('views/Diamond'))
const Prediction= React.lazy(()=> import('views/Prediction'))
const Referrallogin = React.lazy(() => import('views/referrallogin'))
const RemoveLiquidity = React.lazy(() => import('views/removeliquidity'))



function callback(measures) {
	// do something with the measures
}

registerObserver({}, callback)



ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter basename="">
			<ToastContainer />
			<div className="main-wrapper" id="MainWrapper">
				<Suspense fallback={<></>}>
					<Switch>
						<Route exact path="/referral" component={Referral} />
						<Route path="/liquidity" exact component={Liquidity} />
						{/* <Route path="/diamond" exact component={Diamond} />
						<Route path="/lottery" exact component={Lottery} />
						<Route path="/prediction" exact component={Prediction} />
						<Route path="/games" exact component={Games} /> */}
						<Route
							path="/remove-liquidity/:tokena/:tokenb"
							component={RemoveLiquidity}
						/>
						<Route path="/exchange" component={Exchange} />
						{/* <Route path="/pools" component={Pools} />
							<Route path="/farms" component={Farms} /> */}
						<Route path="/home" component={Home} />
						<Route exact path="/referral/:ref" component={Referrallogin} />
						<Route exact path="/*" component={Home}>
							<Redirect to="/home" />
						</Route>
					</Switch>
				</Suspense>
			</div>
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);
